import '../../sass/global.scss'

const menuMobile = () => {
	let btn = document.querySelector('.btnMenuMobile') as HTMLElement;
	let menu = document.querySelector('.header') as HTMLElement;
	btn.addEventListener('click', function(e){
		menu.classList.toggle('visible_menu');
	})

	let items = document.querySelectorAll('.menu--mobile a') as any;
	items.forEach((item:any) => {
		item.addEventListener('click', function(){
			menu.classList.remove('visible_menu');
		})
	});
}

(function page() {
	menuMobile();
})()